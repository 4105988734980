import './style.css'
import * as dat from 'dat.gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import firefliesVertexShader from './shaders/vertex.glsl'
import firefliesFragmentShader from './shaders/fragment.glsl'

/**
 * Spector JS
 */
//const SPECTOR = require('spectorjs')
//const spector = new SPECTOR.Spector()
//spector.displayUI()

/**
 * Base
 */
//Debug
//const gui = new dat.GUI({
//    width: 400
//})

// Canvas
const canvas = document.querySelector('canvas.webgl')
$(".popup").hide()

// Scene
const scene = new THREE.Scene()
const scene2 = new THREE.Scene()

/**
 * Loading bar
 */
const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
      uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
       {
           gl_Position = vec4(position, 1.0);
       }
   `,
   fragmentShader: `
      uniform float uAlpha;

       void main()
       {
           gl_FragColor = vec4(0.18, 0.388, 0.42, uAlpha);
       }
   `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene2.add(overlay)

/**
 * Loaders
 */
const loadingBarElement = document.querySelector('.loading-bar')
//console.log(loadingBarElement)

const loadingManager = new THREE.LoadingManager(
  //Loaded
  () =>
  {
    gsap.delayedCall(0.5, () =>
    {
      gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 })
      loadingBarElement.classList.add('ended')
      loadingBarElement.style.transform = ''
    })
  },

  //Progress
  (itemUrl, itemsLoaded, itemsTotal) =>
  {
    const progressRatio = itemsLoaded / itemsTotal
    loadingBarElement.style.transform = `scaleX(${progressRatio})`
  }
)

// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

// Draco loader
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Textures and materials
 */
const bakedTexture = textureLoader.load('Bake.jpg')
bakedTexture.flipY = false
bakedTexture.encoding = THREE.sRGBEncoding

const clickanddragTexture = textureLoader.load('clickanddrag.png')

const robottextTexture = textureLoader.load('robottext.png')

const highlightTexture = textureLoader.load('gradient.jpg')

//Environment map
const environmentMap = cubeTextureLoader.load([
  'environmentMap/px.png',
  'environmentMap/nx.png',
  'environmentMap/py.png',
  'environmentMap/ny.png',
  'environmentMap/pz.png',
  'environmentMap/nz.png',
  ])
scene.background = environmentMap

//Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture, side: THREE.DoubleSide })

//Window material
const windowMaterial = new THREE.MeshBasicMaterial({ color: 0x945051 })
windowMaterial.opacity = 0.3
windowMaterial.transparent = true

//Highlight material
const highlightMaterial = new THREE.MeshBasicMaterial({ map: highlightTexture, side: THREE.DoubleSide })

//navigation and messages materials
const clickanddragMaterial = new THREE.MeshBasicMaterial({ color: 0x060D0E, map: clickanddragTexture, side: THREE.DoubleSide })
clickanddragMaterial.transparent = true

const robottextMaterial = new THREE.MeshBasicMaterial({ color: 0x285943, map: robottextTexture, side: THREE.DoubleSide })
robottextMaterial.transparent = true

/**
 * Videos
 */
//VR screen
const vrvideo = document.getElementById( 'VRvideo' );
const vrvideoTexture = new THREE.VideoTexture( vrvideo );
vrvideoTexture.flipY = false;
vrvideo.play()

//3D screen
const tdvideo = document.getElementById( 'TDvideo' );
const tdvideoTexture = new THREE.VideoTexture( tdvideo );
tdvideoTexture.flipY = false;
tdvideo.play()

//Planes with text
const clickanddragGeometry = new THREE.PlaneGeometry( 0.6, 0.6 );
const clickanddrag = new THREE.Mesh( clickanddragGeometry, clickanddragMaterial );
scene.add( clickanddrag );
clickanddrag.frustumCulled = false
clickanddrag.position.set(2.49, 2.1, 4.5)
//gui.add(clickanddrag.position, 'x')
//gui.add(clickanddrag.position, 'y')
//gui.add(clickanddrag.position, 'z')

//const robottext = new THREE.Mesh( robottextGeometry, robottextMaterial );
//scene.add( robottext );
//robottext.frustumCulled = false
//robottext.position.set(4.9, 2.4, 2.7)
//robottext.rotation.y = 5.6
//gui.add(robottext.position, 'x')
//gui.add(robottext.position, 'y')
//gui.add(robottext.position, 'z')
//gui.add(robottext.rotation, 'y')

/**
 * Models
 */
let mixer = null

let hoverableObjects = []

let objs = []

//Office
gltfLoader.load(
  'portfolio.glb',
  (gltf) =>
    {
    gltf.scene.traverse((child) =>
    {
      //console.log(child)
      child.material = bakedMaterial
      child.frustumCulled = false
    })

    //Window meshes
    const windowsMesh = gltf.scene.children.find(child => child.name === 'windows')
    windowsMesh.material = windowMaterial

    //console.log(gltf.scene)

    //Animations
    mixer = new THREE.AnimationMixer(gltf.scene);
    gltf.animations.forEach( (clip) => {
      mixer.clipAction(clip).play()
    })

    //VR screen
    const vrscreenMesh = gltf.scene.children.find(child => child.name === 'VRscreen')
    vrscreenMesh.material = new THREE.MeshBasicMaterial( { map: vrvideoTexture } );

    //3D screen
    const tdscreenMesh = gltf.scene.children.find(child => child.name === '3Dscreen')
    tdscreenMesh.material = new THREE.MeshBasicMaterial( { map: tdvideoTexture } );

    //Hoverable objects
    const modelingMesh = gltf.scene.children.find(child => child.name === 'modelingmenu')

    const aboutMesh = gltf.scene.children.find(child => child.name === 'aboutmenu')

    const immersiveMesh = gltf.scene.children.find(child => child.name === 'immersivemenu')

    const outlines = new THREE.Object3D()
    outlines.add(modelingMesh, aboutMesh, immersiveMesh)

    scene.add(gltf.scene, outlines)

    hoverableObjects = outlines;

    objs.push(modelingMesh, aboutMesh, immersiveMesh)
    })



/**
 * Shaders
 */
//About me
const firefliesGeometry = new THREE.BufferGeometry()
const firefliesCount = 35
const positionArray = new Float32Array(firefliesCount * 3)
const scaleArray = new Float32Array(firefliesCount)
const colorArray = new Float32Array(firefliesCount * 3)

for(let i = 0; i < firefliesCount; i++)
 {
   positionArray[i * 3 + 0] = (Math.random() + 1.32) * 0.53  //esta es la X
   positionArray[i * 3 + 1] = (Math.random() + 0.59) * 1.25 //esta es la Y
   positionArray[i * 3 + 2] = (Math.random() + 6) * 0.3 //esta es la Z

   scaleArray[i] = Math.random()

   colorArray[i * 3 + 0] = Math.random()
   colorArray[i * 3 + 1] = Math.random()
   colorArray[i * 3 + 2] = Math.random()
 }

firefliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
firefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))
firefliesGeometry.setAttribute('color', new THREE.BufferAttribute(colorArray, 3))

const firefliesMaterial = new THREE.ShaderMaterial({
   uniforms:
   {
     uTime: { value: 0 },
     uPixelRatio: { value: Math.min(window.devicePixelRatio, 2)},
     uSize: { value: 200 }
   },
   vertexShader: firefliesVertexShader,
   fragmentShader: firefliesFragmentShader,
   //transparent: true,
   blending: THREE.AdditiveBlending,
   depthWrite: false,
   vertexColors: true
 })

//gui.add(firefliesMaterial.uniforms.uSize, 'value').min(0).max(500).step(1).name('aboutfirefliesSize')

const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)
scene.add(fireflies)

//3D modeling
const modelingfirefliesGeometry = new THREE.BufferGeometry()
const modelingpositionArray = new Float32Array(firefliesCount * 3)

for(let i = 0; i < firefliesCount; i++)
{
  modelingpositionArray[i * 3 + 0] = (Math.random() - 4) * 1  //esta es la X
  modelingpositionArray[i * 3 + 1] = (Math.random() + 2.2) * 0.9 //esta es la Y
  modelingpositionArray[i * 3 + 2] = (Math.random() + 6) * 0.9 //esta es la Z
}

modelingfirefliesGeometry.setAttribute('position', new THREE.BufferAttribute(modelingpositionArray, 3))
modelingfirefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))
modelingfirefliesGeometry.setAttribute('color', new THREE.BufferAttribute(colorArray, 3))

const modelingfireflies = new THREE.Points(modelingfirefliesGeometry, firefliesMaterial)
scene.add(modelingfireflies)

//immersive experiences
const immersivefirefliesGeometry = new THREE.BufferGeometry()
const immersivepositionArray = new Float32Array(firefliesCount * 3)

for(let i = 0; i < firefliesCount; i++)
{
  immersivepositionArray[i * 3 + 0] = (Math.random() + 11.9) * 0.5  //esta es la X
  immersivepositionArray[i * 3 + 1] = (Math.random() + 4.1) * 0.5 //esta es la Y
  immersivepositionArray[i * 3 + 2] = (Math.random() + 3.8) * 0.8 //esta es la Z
}

immersivefirefliesGeometry.setAttribute('position', new THREE.BufferAttribute(immersivepositionArray, 3))
immersivefirefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))
immersivefirefliesGeometry.setAttribute('color', new THREE.BufferAttribute(colorArray, 3))

const immersivefireflies = new THREE.Points(immersivefirefliesGeometry, firefliesMaterial)
scene.add(immersivefireflies)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    //Update fireflies
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set(2.5, 1.5, 7.1)

//gui.add(camera.position, 'x')
//gui.add(camera.position, 'y')
//gui.add(camera.position, 'z')

scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas);
controls.rotateSpeed *= -1;
controls.target.set(2.5, 1.4, 5.2)
controls.minPolarAngle = Math.PI/2.03
controls.maxPolarAngle = Math.PI/2.03
controls.enableDamping = true
controls.dampingFactor = 0.09
controls.enableZoom = false
controls.enablePan = false

//gui.add(controls.target, 'x')
//gui.add(controls.target, 'y')
//gui.add(controls.target, 'z')

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding
renderer.autoClear = false;

/**
 * Mouse
 */
const mouse = new THREE.Vector2()

window.addEventListener('mousemove', () =>
{
  mouse.x = event.clientX / sizes.width * 2 - 1
  mouse.y = - (event.clientY / sizes.height) * 2 + 1
})

//Clicks
function showmodelingModal()
  {$("#modelingModal").modal('show')}

function showaboutModal()
  {$("#aboutModal").modal('show')}
  //{$("#aboutModal").modal('show').find("#about-modal-content").load("about.html")}

function showimmersiveModal()
  {$("#immersiveModal").modal('show')}

window.addEventListener('click', () =>
{
    scene.remove(clickanddrag);
    if(currentIntersect)
    {
        switch(currentIntersect.object.name)
        {
            case 'aboutmenu':
                //console.log('click on object 1')
                showaboutModal()
                break

            case 'modelingmenu':
                //console.log('click on object 2')
                showmodelingModal()
                break

            case 'immersivemenu':
                //console.log('click on object 3')
                showimmersiveModal()
                break
        }
    }
})

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()

//Hover
function highlight()
{
  if ( hoverableObjects ) {
    hoverableObjects.traverse(function (child) {
        child.material = highlightMaterial;
    });
  }
}

function unHighlight()
{
  if ( hoverableObjects ) {
    hoverableObjects.traverse(function (child) {
        child.material = bakedMaterial;
    });
  }
}

let currentIntersect = null

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //Update fireflies
    firefliesMaterial.uniforms.uTime.value = elapsedTime

    //Update Mixer
    if(mixer !== null)
    {
      mixer.update( deltaTime )
    }

    //Raycaster
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects( objs )

    if(intersects.length)
    {
    //console.log(currentIntersect)
        if(!currentIntersect)
        {
            $('html,body').css('cursor', 'pointer');
            highlight();
        }
        currentIntersect = intersects[0]
    }
    else
    {
        if(currentIntersect)
        {
            $('html,body').css('cursor', 'default');
            unHighlight()
        }
        currentIntersect = null
    }

    // Update controls
    controls.update()

    // Render
    renderer.clear();
    renderer.render( scene, camera );
    renderer.clearDepth();
    renderer.render( scene2, camera );

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}
tick()
